<template>
  <ImageReview
    v-if="props.nid && props.type"
    :content-type="props.type"
    :nid="props.nid"
    :download-nick="props.downloadnick"
    :software="props.software"
    :to="props.innerlink || props.innerLink"
    :link-class="imgClass"
    :img-class="imgClass"
  ></ImageReview>
</template>

<script setup>
defineOptions({ inheritAttrs: false });
const props = defineProps({
  nid: { type: [Number, String], required: true, default: 0 },
  type: {
    type: String,
    required: true,
    default: "",
  },
  downloadnick: { type: String, default: "" },
  software: { type: String, default: "" },
  innerlink: { type: String, default: "" },
  innerLink: { type: String, default: "" },
  position: { type: String, default: "left" },
  // title: { type: String, required: false, default: "" },
});
const imgClass = computed(() => (props.position ? `img-fluid pos-${props.position}` : "img-fluid"));
// const link = computed(() => props.innerlink || props.innerLink);
</script>
